import React, { useEffect, useState } from 'react';
import { BarChart, Bar, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import { apiService } from '../api/index'; // Import the apiService

function Analytics() {
    const [selectedData, setSelectedData] = useState('Claude 2');
  const [lineData, setLineData] = useState([]); // New state for lineData
  const [histogramData, setHistogramData] = useState([]); // New state for histogramData

  // Fetch histogram data
  useEffect(() => {
    apiService.getHistogramData().then((res) => {
      console.log(res.body);
      setHistogramData(res.body);
    }).catch((err) => {
      console.error(err);
    });
  }, []);

  // Fetch line chart data
  useEffect(() => {
    apiService.getLineChartData(selectedData).then((res) => {
        console.log(res.body);
      setLineData(res.body);
    }).catch((err) => {
      console.error(err);
    });
  }, [selectedData]);

  
    return (
      <div className="text-center">
        <div className="bg-gray-200 p-5 rounded-md mb-5">
          <h1 className="font-bold text-xl">Analytics Dashboard</h1>
          <p className="mt-2">
            This dashboard provides insights into key metrics via visualizations.
            Utilize the dropdown menu to toggle between data sets for more nuanced analysis.
          </p>
        </div>
  
        <hr className="w-4/5 mb-5" />
  
        {/* Histogram */}
        <h2 className="font-bold text-lg mb-3">Histogram</h2>
      <div className="inline-block">
        <BarChart width={500} height={300} data={histogramData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Bar dataKey="rank" fill="#8884d8" /> {/* Change dataKey to rank */}
        </BarChart>
      </div>

  
        {/* Line Chart */}
        <h2 className="font-bold text-lg mb-3 mt-5">Line Chart Over Time</h2>
                {/* Dropdown for line chart */}
      {/* Dropdown for line chart */}
<div className="mt-5">
<select 
  onChange={(e) => setSelectedData(e.target.value)} 
  className="p-2 rounded-md bg-blue-100 border-2 border-blue-500 text-blue-700 font-semibold"
  value={selectedData} // Set the current value
>
  <option value="Claude 2" selected>Claude 2</option> {/* Remove 'selected' attribute */}
  <option value="Claude-instant-v1">Claude-instant-v1</option>
  <option value="GPT 3.5">GPT 3.5</option>
  <option value="GPT 4">GPT 4</option>
  <option value="Llama 2">Llama 2</option>
  <option value="PaLM2">PaLM2</option>
  <option value="StableBeluga2">StableBeluga2</option>
</select>
</div>
<div className="inline-block">
        <LineChart width={500} height={300} data={lineData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="dt" /> {/* dt seems to be the time axis */}
          <YAxis />
          <Tooltip />
          <Line type="monotone" dataKey="avg" stroke="#8884d8" /> {/* Change dataKey to avg */}
        </LineChart>
      </div>
      </div>
    );
  }
export default Analytics;
