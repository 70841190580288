import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { apiService } from '../api/index'; // Import your API service

function Leaderboard() {
  const [activeCategory, setActiveCategory] = useState("overall");
  const [data, setData] = useState([]);
  const [previousData, setPreviousData] = useState(null);
  const [expandedRow, setExpandedRow] = useState(null);
  const [totalVotes, setTotalVotes] = useState(null);

    // Added a new state to hold categories
    const [categories, setCategories] = useState(["overall"]);

  function getTierClass(percentile) {
    if (percentile <= 10) return "bg-gold-tier";
    if (percentile <= 30) return "bg-silver-tier";
    if (percentile <= 60) return "bg-bronze-tier";
    return "bg-other-tier";
  }
  useEffect(() => {
    apiService.getTotalVotes()
      .then(response => {
        if (response && response.body.totalVotes != null) {
          console.log(response.body.totalVotes);
          setTotalVotes(response.body.totalVotes);
        }
      })
      .catch(error => {
        console.error("Error fetching total votes:", error);
      });
  }, []);

  useEffect(() => {
    // Fetch categories from the API
    apiService.getDistinctCategories()
      .then(response => {
        if (response && response.body.categories) {
          setCategories(["overall", ...response.body.categories]);
        }
      })
      .catch(error => {
        console.error("Error fetching categories:", error);
      });
  }, []);

  
  useEffect(() => {
    const now = Date.now();
    const cacheKey = `leaderboard_${activeCategory}`;
    const timestampKey = `leaderboard_timestamp_${activeCategory}`;
    const cachedData = localStorage.getItem(cacheKey);
    const timestamp = localStorage.getItem(timestampKey);

    if (cachedData && timestamp && now - timestamp < 4 * 60 * 60 * 1000) {
      setData(JSON.parse(cachedData));
    } else {
      apiService.getRankings(activeCategory)
      .then(response => {
        if (Array.isArray(response.body)) {
          setPreviousData(JSON.parse(cachedData));
          const data = response.body.map(ranking => ({
            modelId: ranking.modelid,
            rank: ranking.rank,
            totalVotes: ranking.total, // Added
            winningVotes: ranking.wins, // Added
            category: ranking.category,
            lastUpdated: ranking.lastUpdated,
            modelName: ranking.Name,
            Edition: ranking.Edition,
            place: ranking.place
          }));

            
            setData(data);
            localStorage.setItem(cacheKey, JSON.stringify(data));
            localStorage.setItem(timestampKey, now.toString());
          } else if (response.body.message) {
            console.warn("No rankings found for category:", activeCategory);
            setData(null);
          } else {
            console.error("Received unexpected data:", response);
          }
        })
        .catch(error => {
          console.error("Error fetching rankings:", error);
        });
    }
  }, [activeCategory]);

  function handleTabClick(category) {
    setActiveCategory(category);
  }

  function handleRowClick(modelId) {
    if (expandedRow === modelId) {
      setExpandedRow(null);
    } else {
      setExpandedRow(modelId);
    }
  }

  const options = categories.map(category => ({ value: category, label: category }));
  const selectedOption = options.find(option => option.value === activeCategory);

  const dataWithArrows = data ? data.map((ranking, index) => {
    const previousIndex = previousData ? previousData.findIndex(r => r.modelId === ranking.modelId) : -1;
    const previousRanking = previousData ? previousData[previousIndex] : null;
  
    if (previousRanking && previousRanking.rank > ranking.rank) return { ...ranking, arrow: "up" };
    if (previousRanking && previousRanking.rank < ranking.rank) return { ...ranking, arrow: "down" };
  
    return ranking;
  }) : [];

  return (
    <div className="bg-gray-100 min-h-screen">
      {/* Description Section */}
      <div className="bg-gray-300 p-6 text-center text-gray-700">
  <h1 className="text-xl font-semibold">Leaderboard Page</h1>
  <p>Explore the rankings of different AI models across various categories.</p>
  {totalVotes !== null && (
    <p className="text-lg font-semibold text-blue-600 hover:underline hover:text-blue-800 transition ease-in-out duration-300 my-2">
      Total Votes: {totalVotes}</p>
  )}
</div>

        {/* Explanation Section */}
  <div className="bg-green-100 p-6 text-center text-gray-700">
    <h2 className="text-xl font-semibold">Why This Leaderboard?</h2>
    <p>The leaderboard ranks AI models on practical topics, making it easier for users to understand which models excel at specific tasks. Whether you're interested in marketing, accounting, writing, or other areas, discover the top-performing models right here.</p>
  </div>
  
      {/* Category Tabs */}
      <div className="mx-10 my-2">
        <label htmlFor="category" className="block text-gray-600 font-medium">Select a Category</label>
        <Select
          value={selectedOption}
          onChange={selected => handleTabClick(selected.value)}
          options={options}
          isSearchable={true}
          placeholder="Search or select a category..."
        />
      </div>
      {/* Widget Icon and Copy/Paste Link */}
      <div className="rounded-t-lg overflow-hidden border-white mx-10">
      <div className="flex justify-end pr-4 pt-4">
        <a href="/embed-code" title="Get Embed Code">
          <i className="fas fa-code text-blue-500 hover:text-blue-700 cursor-pointer"></i> {/* Font Awesome Icon */}
        </a>
      </div>



      <div className="bg-gradient-to-r from-gray-100 to-gray-200 min-h-screen p-6">
    <div className="rounded-lg shadow-md overflow-hidden border-white mx-10 bg-white">
        {dataWithArrows.length !== 0 ? (
            <ul className="divide-y divide-gray-200">
                {dataWithArrows.map((model, index) => {
      const isExpanded = expandedRow === model.modelId;
      const percentile = (index / dataWithArrows.length) * 100;
      const tierClass = getTierClass(percentile);
      const arrow = model.arrow === "up" ? "🔼" : model.arrow === "down" ? "🔽" : "";

      return (
        <li className={`relative ${tierClass} ${isExpanded ? 'bg-lighter-slate' : 'bg-gray-50'} hover:bg-gray-100 cursor-pointer p-3`} onClick={() => handleRowClick(model.modelId)} key={model.modelId}>
            <button aria-expanded={isExpanded} className="group w-full py-3 focus-visible:outline-none">
              <span className="absolute left-0 h-[28px] w-[6px] z-10 invisible group-focus-visible:visible bg-blue-200"></span>
              <div className="flex text-lg font-medium text-black">
                <div className="w-10 shrink-0 ml-4 text-center">{index + 1} {arrow}</div>
                <div className="text-left pr-14">{model.modelName}</div>
              </div>
            </button>
          <span className="absolute top-3 bottom-3 left-0 w-[6px] bg-blue-600"></span>
          {isExpanded && (
  <div className="flex leading-4 justify-center xsm:justify-start text-sm mt-4 mb-6 ml-6 flex flex-row space-x-6 xsm:ml-10">
    <div className="flex-1 flex flex-col text-black">
      <h3 className="mb-2 font-semibold text-lg">Model Info</h3>
      <dl className="space-y-4 sm:space-y-2">
        <div><dt className="block mb-1 text-sm sm:mb-0 sm:inline-block sm:w-28">Model Edition</dt><dd className="inline-block font-semibold text-base sm:text-sm sm:text-right sm:w-28">{model.Edition}</dd></div>
        <div><dt className="block mb-1 text-sm sm:mb-0 sm:inline-block sm:w-28">Date added</dt><dd className="inline-block font-semibold text-base sm:text-sm sm:text-right sm:w-28">{new Date(model.lastUpdated).toLocaleDateString()}</dd></div>
      </dl>
    </div>
    <div className="border-l border-gray-300" /> {/* Vertical Divider */}
    <div className="flex-1 flex flex-col text-black">
      <h3 className="mb-2 font-semibold text-lg">Performance</h3>
      <dl className="space-y-4 sm:space-y-2">
        <div><dt className="block mb-1 text-sm sm:mb-0 sm:inline-block sm:w-28">ELO rating</dt><dd className="inline-block font-semibold text-base sm:text-sm sm:text-right sm:w-28">{model.rank}</dd></div>
        <div><dt className="block mb-1 text-sm sm:mb-0 sm:inline-block sm:w-28">Total Votes</dt><dd className="inline-block font-semibold text-base sm:text-sm sm:text-right sm:w-28">{model.totalVotes}</dd></div>
        <div><dt className="block mb-1 text-sm sm:mb-0 sm:inline-block sm:w-28">Winning Votes</dt><dd className="inline-block font-semibold text-base sm:text-sm sm:text-right sm:w-28">{model.winningVotes}</dd></div>
      </dl>
    </div>
  </div>
)}

      </li>
    );
  })}
</ul>
) : (
  <div className="text-center mt-4 text-gray-600">
    Sorry! Not enough data for this category yet.
  </div>
)}
      </div>
    </div>
    </div>
    <style jsx>{`
    .bg-gold-tier {
        background-color: #FFD700; /* gold shade */
    }

    .bg-silver-tier {
        background-color: #C0C0C0; /* silver shade */
    }

    .bg-bronze-tier {
        background-color: #CD7F32; /* bronze shade */
    }

    .bg-other-tier {
        background-color: #f0f0f0; /* softer neutral shade */
    }
`}</style>
    </div>
    );
}


export default Leaderboard;