import React, { useState } from 'react';
import { apiService } from '../api/index';

function Submissions() {
  const [submissionType, setSubmissionType] = useState('');
  const [promptText, setPromptText] = useState('');
  const [modelName, setModelName] = useState('');
  const [modelURL, setModelURL] = useState('');
  const [category, setCategory] = useState(null);
  const [notes, setNotes] = useState('');
  const [feedback, setFeedback] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errors, setErrors] = useState({});

  const handleSubmissionTypeChange = (e) => {
    setSubmissionType(e.target.value);
  }

  const validateUrl = (url) => {
    const pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i'
    );
    return !!pattern.test(url);
  };

  const validateFields = () => {
    let errors = {};
    let isValid = true;

    if (submissionType === 'prompt') {
      if (promptText.trim().length < 10) {
        errors.promptText = 'Prompt must be at least 10 characters';
        isValid = false;
      }
      if (!category) {
        errors.category = 'Category is required';
        isValid = false;
      }
    } else if (submissionType === 'model') {
      if (!modelName.trim()) {
        errors.modelName = 'Model Name is required';
        isValid = false;
      }
      if (!modelURL.trim() || !validateUrl(modelURL)) {
        errors.modelURL = 'Valid Model URL is required';
        isValid = false;
      }
    }

    if (!submissionType) {
      errors.submissionType = 'Submission type is required';
      isValid = false;
    }

    if (!notes.trim()) {
      errors.notes = 'Notes/Descriptions is required';
      isValid = false;
    }

    setErrors(errors);

    return isValid;
  };



    const handleSubmit = async () => {
        if (!validateFields()) {
            return;
          }
        let submissionData;
        console.log('handle submit')
        if (submissionType === 'prompt') {
            submissionData = {
                type: 'prompt',
                name: promptText,  // content of the prompt as the name
                category,
                notes,
                url: null
            };
        } else if (submissionType === 'model') {
            submissionData = {
                type: 'model',
                name: modelName,
                url: modelURL,
                category: null,
                notes
            };
        }

        console.log(submissionData);
        try {
            const apiResponse = await apiService.addSubmission(submissionData);
            console.log(apiResponse.status);
            
            if (apiResponse.status === 201) {
                console.log('success!')
                setFeedback(true);
                setIsSubmitted(true);
                setTimeout(() => setFeedback(false), 3000); 
                
                   // hide feedback after 3 seconds
            }
        } catch (error) {
            console.error("Error during submission:", error);
            // Here you can set some state variable to show an error message to the user if needed.
        }
    }
    return (
        <div className="bg-gray-100 min-h-screen p-6">
          <div className="bg-blue-500 text-white p-6 mb-8 rounded-md shadow-md">
            Use this page to submit new prompts or models.
          </div>
      
          {isSubmitted ? (
            <div className="bg-white p-8 rounded-md shadow-lg text-center">
              <h1 className="text-2xl font-bold mb-6">Submission Successful!</h1>
              <p>Your submission has been received and is currently being processed. Thank you!</p>
            </div>
          ) : (
            <div className="bg-white p-8 rounded-md shadow-lg space-y-6">
              <select className="w-full border p-2 rounded-md" onChange={handleSubmissionTypeChange}>
                <option value="default" selected disabled>Select submission type</option>
                <option value="prompt">Prompt</option>
                <option value="model">Model</option>
              </select>
              <p className="text-red-500">{errors.submissionType}</p>
      
              {submissionType === 'prompt' && (
                <div className="space-y-6">
                  <input type="text" placeholder="Enter the prompt text here..." className="w-full border p-2 rounded-md" value={promptText} onChange={e => setPromptText(e.target.value)} />
                  <p className="text-red-500">{errors.promptText}</p>
                  <select className="w-full border p-2 rounded-md" value={category} onChange={e => setCategory(e.target.value)}>
                    <option value="default" disabled>Select category</option>
                    <option value="category1">Category 1</option>
                    <option value="category2">Category 2</option>
                  </select>
                  <p className="text-red-500">{errors.category}</p>
                  <textarea placeholder="Notes/Descriptions" className="w-full border p-4 rounded-md" value={notes} onChange={e => setNotes(e.target.value)}></textarea>
                  <p className="text-red-500">{errors.notes}</p>
                </div>
              )}
      
              {submissionType === 'model' && (
                <div className="space-y-6">
                  <input type="text" placeholder="Model Name" className="w-full border p-2 rounded-md" value={modelName} onChange={e => setModelName(e.target.value)} />
                  <p className="text-red-500">{errors.modelName}</p>
                  <input type="text" placeholder="Paste the model URL here..." className="w-full border p-2 rounded-md" value={modelURL} onChange={e => setModelURL(e.target.value)} />
                  <p className="text-red-500">{errors.modelURL}</p>
                  <textarea placeholder="Notes/Descriptions" className="w-full border p-4 rounded-md" value={notes} onChange={e => setNotes(e.target.value)}></textarea>
                  <p className="text-red-500">{errors.notes}</p>
                </div>
              )}
      
              <button className="btn bg-blue-500 hover:bg-blue-600 text-white w-full py-2 rounded-md transition duration-300" onClick={handleSubmit}>Submit</button>
            </div>
          )}
        </div>
      );
}



export default Submissions;