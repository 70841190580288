import React, { useState, useEffect } from 'react';
import Select from 'react-select'; // Assuming you use react-select for dropdown
import { apiService } from '../api/index';

const VotingPage = () => {
  const [modelOutputs, setModelOutputs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [category, setCategory] = useState(null);

  // Categories for the select menu
// Added state for dynamic categories
  const [categories, setCategories] = useState([{ value: null, label: '-' }]);
  useEffect(() => {
    // Fetch categories from the API
    apiService.getDistinctCategories()
      .then(response => {
        if (response && response.body.categories) {
          const newCategories = response.body.categories.map(cat => ({ value: cat, label: cat }));
          setCategories([{ value: null, label: '-' }, ...newCategories]);
        }
      })
      .catch(error => {
        console.error("Error fetching categories:", error);
      });
  }, []);
  useEffect(() => {
    fetchModelOutputs();
  }, [category]);

  async function fetchModelOutputs() {
    setLoading(true);
    try {
      const response = await apiService.getRandomModelOutputs(category); // Modify API to accept category
      console.log(response.body);
      if(response.status === 200) {
        setModelOutputs(response.body);
      } else {
        console.error("Unexpected response status:", response.status);
      }

      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch model outputs:", error);
      setLoading(false);
    }
  }

  async function handleSkip() {
    fetchModelOutputs();
  }


  /**
   * Handles the voting for a model. Posts the vote and fetches new models for next vote.
   * @param {string} winningModelOutputId - ID of the winning model output.
   * @param {string} losingModelOutputId - ID of the losing model output.
   * @param {string} promptId - ID of the prompt.
   */
  async function handleVote(winningModelOutputId, losingModelOutputId, promptId) {
    try {
      const voteResponse = await apiService.postVote({
        winningModelOutputId,
        losingModelOutputId,
        promptId
      });

      if(voteResponse.status !== 200) {
          console.error("Unexpected response status during vote submission:", voteResponse.status);
          return;
      }

      // After successfully voting, fetch new outputs.
      const response = await apiService.getRandomModelOutputs(category);
      console.log(response.body)
      if(response.status === 200) {
          setModelOutputs(response.body);
      } else {
          console.error("Unexpected response status after vote:", response.status);
      }

    } catch (error) {
      console.error("Failed to submit vote and fetch new models:", error);
    }
}

  return (
    <div className="bg-gray-100 min-h-screen p-6">
      {/* Category Selection */}
      <div className="mb-8">
        <label className="text-lg font-bold mb-2">Select Category:</label>
        <Select
          options={categories}
          onChange={selected => setCategory(selected.value)}
          className="capitalize"
        />
      </div>

{/* Sample Prompt Display */}
<div className="bg-blue-100 p-6 rounded-md mb-10 text-center shadow-lg">
  <h2 className="text-xl font-bold mb-1">PROMPT</h2>
  <h3 className="text-2xl font-bold mb-2">
    {loading || !modelOutputs[0] ? "Loading prompt..." : modelOutputs[0].promptText}
  </h3>
</div>

<h2 className="text-xl font-bold mb-4 text-center w-full">MODEL OUTPUTS</h2>

{/* Model Outputs and Voting Buttons */}
<div className="flex justify-between space-x-8">
  {loading ? (
    <p>Loading...</p>
  ) : (
    modelOutputs.map((output, index) => (
      <div key={output.id} className={`model-output bg-white w-1/2 p-6 rounded-md shadow-lg transition-transform transform hover:scale-105 relative ${index === 0 ? 'mr-4' : 'ml-4'}`}>
        <h4 className="text-gray-700 mb-4">MODEL {index + 1}</h4>
        <p className="text-gray-700 mb-6">{output.outputText}</p>
        <div className="choose-overlay absolute inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center opacity-0 hover:opacity-100 transition-opacity">
          <button className="btn bg-blue-500 hover:bg-blue-600 text-white rounded-md py-2 transition duration-300"
          onClick={() => handleVote(output.modelId, modelOutputs[1 - index].modelId, output.promptId)}>Choose</button>
        </div>
      </div>
    ))
  )}
</div>

      {/* Skip Button */}
      <div className="text-center mt-6">
        <button className="btn bg-gray-400 hover:bg-gray-500 text-white rounded-md px-8 py-2 transition duration-300"
        onClick={handleSkip}>Skip</button>
      </div>
    </div>
  );
}

export default VotingPage;