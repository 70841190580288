import React, {useState} from 'react';
import { apiService } from '../api/index';

function FeedbackDialog({ isOpen, onClose, position }) {

    const [feedbackText, setFeedbackText] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleSubmit = async () => {
        try {
            const response = await apiService.submitFeedback({ feedbackText });
            console.log('Feedback submitted successfully', response);
            setIsSubmitted(true);
            // You can handle success logic here, e.g., show a confirmation message
        } catch (error) {
            console.error('An error occurred while submitting feedback', error);
            // Handle error logic here, e.g., show an error message to the user
        }
    };

    if (!isOpen) {
        return null;
    }
    if (isSubmitted) {
        return (
            <div
                style={{
                    top: position.top + position.height,
                    left: position.left,
                }}
                className="absolute bg-white p-6 rounded-md shadow-lg"
            >
                <h3 className="text-lg font-bold mb-4">Thank you!</h3>
                <p>Your feedback has been submitted successfully.</p>
                <button className="bg-blue-500 text-white p-2 rounded-md" onClick={onClose}>
                    Close
                </button>
            </div>
        );
    }
    const handleBackdropClick = (e) => {
        // Get the dialog box element
        const dialogBox = document.getElementById('dialog-box');

        // If the click event's target is outside the dialog box, call onClose
        if (dialogBox && !dialogBox.contains(e.target)) {
            onClose();
        }
    };

    return (
        <div className="fixed inset-0 z-50" onClick={handleBackdropClick}>
            <div className="absolute bg-black opacity-50 inset-0"></div>
            <div
                id="dialog-box" // Add an id to reference this element
                style={{
                    top: position.top + position.height,
                    left: position.left,
                }}
                className="absolute bg-white p-6 rounded-md shadow-lg"
            >
                <h3 className="text-lg font-bold mb-4">Your Feedback</h3>
                <textarea className="w-full h-32 p-2 border rounded-md mb-4" value={feedbackText} onChange={e => setFeedbackText(e.target.value)}></textarea>
            <button className="bg-blue-500 text-white p-2 rounded-md" onClick={handleSubmit}>
                    Submit
                </button>
            </div>
        </div>
    );
}


export default FeedbackDialog;