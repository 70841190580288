import React from 'react';
import {
  BrowserRouter as  Router,
  Routes,
  Route
} from 'react-router-dom';
import Header from './Components/Header';
import Footer from './Components/Footer';
import Home from './Pages/Leaderboard'; // Assuming you've named your homepage component 'Home'
import Voting from './Pages/VotingPage';
import Submissions from './Pages/Submissions';
import AboutPage from './Pages/About';
import Changelog from './Pages/Changelog';
import Analytics from './Pages/Analytics';

function App() {
    return (
        <Router>
            <div className="App">
                <Header />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/voting" element={<Voting />} />
                    <Route path="/submissions" element={<Submissions />} />
                    <Route path="/about" element={<AboutPage/>} />
                    <Route path="/changelog" element={<Changelog/>} />
                    <Route path="/analytics" element={<Analytics/>} />
                    {/* Add more routes as needed */}
                </Routes>
                <Footer /> 
            </div>
        </Router>
    );
}

export default App;