import React from 'react';
import { Link } from 'react-router-dom';
import TwitterLogo from '../assets/x-twitter.svg';

function Footer() {
    return (
        <footer className="bg-white py-4 shadow-md">
            <ul className="flex space-x-4 justify-center text-gray-600">
                <li><Link to="/about">About</Link></li>
                <li><Link to="/blog">Blog</Link></li>
                <li>
                    <a href="https://twitter.com/brandondataguy" target="_blank" rel="noreferrer">
                    <img src={TwitterLogo} alt="Twitter Logo" className="h-6 w-6 text-blue-400" />
                    </a>
                </li>
            </ul>
        </footer>
    );
}

export default Footer;