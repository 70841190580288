
function AboutPage() {
    return (
      <div className="bg-gray-100 min-h-screen p-6">
        <h1 className="text-4xl font-bold mb-4 text-center">About Us</h1>
  
        <section className="mb-8">
          <h2 className="text-2xl font-bold">Introduction</h2>
          <p>Explore, rank, and evaluate AI models across various domains, from content generation to sports analysis, with our innovative platform using ELO ranking.</p>
        </section>
  
        <section className="mb-8">
          <h2 className="text-2xl font-bold">Our Ranking Method: ELO</h2>
          <p>The ELO system calculates the relative skill levels of players in zero-sum games. We've adapted this proven system to rank AI models, ensuring a fair and accurate representation of their abilities across various tasks and domains.</p>
        </section>
  
        <section className="mb-8">
          <h2 className="text-2xl font-bold">Mission Statement</h2>
          <p>We aim to create a transparent and community-driven platform for evaluating AI models through public voting and insightful analytics, with a focus on explainability and innovation.</p>
        </section>
  
        <section className="mb-8">
          <h2 className="text-2xl font-bold">Features and Functionality</h2>
          <ul>
            <li>Vote on AI models across different categories using ELO ranking</li>
            <li>Submit new models for ranking with demo or example output</li>
            <li>Explore leaderboards and detailed analytics in various categories</li>
            <li>Contribute to evaluation sets for better AI understanding</li>
          </ul>
        </section>
  
        <section className="mb-8">
          <h2 className="text-2xl font-bold">Future Goals</h2>
          <p>We are committed to expanding our features and modalities, continually enhancing user experience, and fostering collaboration within the AI community.</p>
        </section>
  
        <section className="mb-8">
          <h2 className="text-2xl font-bold">Building Explainable Evaluation Sets</h2>
          <p>We believe in transparency and understanding in AI evaluation. Our platform is designed to offer evaluation sets that provide greater insight and explainability, moving beyond mere numerical rankings.</p>
        </section>

      <section className="mb-8">
        <h2 className="text-2xl font-bold">How It Works</h2>
        <p>Simply select a category, review the AI-generated responses, and vote for the one you find most appealing. Your votes shape the leaderboards!</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-bold">Technology Used</h2>
        <p>We leverage a modern tech stack and rigorous evaluation methods to ensure a seamless and unbiased ranking of AI models.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-bold">Potential Applications</h2>
        <p>Our platform supports open-sourced evaluation sets and analytics, fostering innovation and collaboration in areas like content generation, marketing, online tutoring, and more.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-bold">Contact Us</h2>
        <p>You can reach us at <a href="mailto:info@yourwebsite.com">info@yourwebsite.com</a>.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-bold">Join Us</h2>
        <p>Get started now by exploring our AI models and casting your votes.</p>
      </section>


    </div>
  );
};

export default AboutPage;