let BASE_URL;

if (process.env.NODE_ENV === 'production') {
  BASE_URL = process.env.REACT_APP_PROD_API_URL;  // Production API Gateway URL
} else if (process.env.NODE_ENV === 'staging') {
  BASE_URL = process.env.REACT_APP_STAGING_API_URL;  // Staging API Gateway URL
} else {
  BASE_URL = 'http://localhost:3000';  // Local development
}

export default BASE_URL;
    
// Generic function to handle requests
async function fetchFromAPI(endpoint, method = 'GET', body) {
    console.log(BASE_URL);
    const config = {
        method: method,
        headers: {
            'Content-Type': 'application/json',
            // Include other headers here like Authorization if needed
        },
    };

    if (body) {
        
        config.body = JSON.stringify(body);
    }
    console.log(endpoint)
    const response = await fetch(`${BASE_URL}${endpoint}`, config);
    console.log('running!')
    if (!response.ok) {
        throw new Error(`API request failed: ${response.statusText}`);
    }

    const responseBody = await response.json();

    return {
        status: response.status,
        body: responseBody
    };
}

export const apiService = {
    // Leaderboard
    getRankings: (category) => fetchFromAPI(`/api/leaderboard/${category}`),
    getModelDetails: (id) => fetchFromAPI(`/api/model/${id}`),
    getTotalVotes: () => fetchFromAPI(`/api/totalvotes`),
    getDistinctCategories: () => fetchFromAPI('/api/categories'),

    // Voting
    postVote: (data) => fetchFromAPI('/api/vote', 'POST', data),
    skipVote: (data) => fetchFromAPI('/api/vote/skip', 'POST', data),
    getRandomModelOutputs: (category) => fetchFromAPI(`/api/vote/random${category ? '?category=' + category : ''}`),
//'/api/categories'
    // Submission (assuming you have post method for this)
    addSubmission: (data) => fetchFromAPI('/api/submission', 'POST', data),
      // Feedback
    submitFeedback: (data) => fetchFromAPI('/api/feedback', 'POST', data),
    //analytics
    getLineChartData: (modelName) => fetchFromAPI(`/api/lineChartData?modelName=${modelName}`),
    getHistogramData: () => fetchFromAPI(`/api/histogramData`),
};