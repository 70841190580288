import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import FeedbackDialog from './FeedbackDialog';

function Header() {
    const location = useLocation();
    const currentPath = location.pathname;
    const [feedbackDialogOpen, setFeedbackDialogOpen] = useState(false);
    const [feedbackButtonPosition, setFeedbackButtonPosition] = useState({});

    const handleFeedbackClick = (e) => {
        setFeedbackButtonPosition(e.target.getBoundingClientRect());
        setFeedbackDialogOpen(true);
    };

    return (
        <>
        <nav className="bg-white py-4 shadow-md">
            <div className="container mx-auto flex justify-between items-center">
                <div className="flex space-x-4">
                    <Link 
                        to="/" 
                        className={`tab ${currentPath === "/" ? "active" : ""}`}
                    >
                        Rankings
                    </Link>
                    <Link 
                        to="/voting" 
                        className={`tab ${currentPath === "/voting" ? "active" : ""}`}
                    >
                        <span className="text-xl">&#x2714;</span> Voting
                    </Link>
                    <Link 
                        to="/analytics" 
                        className={`tab ${currentPath === "/analytics" ? "active" : ""}`}
                    >
                        <span className="text-xl">#</span> Analytics
                    </Link>
                    <Link 
                        to="/submissions" 
                        className={`tab ${currentPath === "/submissions" ? "active" : ""}`}
                    >
                        <span className="text-xl">+</span> Submissions
                    </Link>
                </div>
                <div className="flex space-x-4">
                    <button className="tab" onClick={handleFeedbackClick}>Feedback</button>
                    <Link 
                        to="/changelog" 
                        className={`tab ${currentPath === "/changelog" ? "active" : ""}`}
                    >
                        Changelog
                    </Link>
                </div>
            </div>
            <style jsx>{`
                .tab {
                    text-transform: uppercase;
                    padding: 0.5rem 1rem;
                    transition: all 0.3s;
                    border-b-2 border-transparent;

                    &:hover {
                        background-color: #f5f5f5;  /* light gray hover effect */
                        border-bottom-color: #e5e5e5;  /* gray bottom border on hover */
                    }

                    &.active {
                        color: #3b82f6;  /* blue color for active tab */
                        border-bottom-color: #3b82f6;  /* blue bottom border for active tab */
                    }
                }
            `}</style>
        </nav>
        <FeedbackDialog position={feedbackButtonPosition} isOpen={feedbackDialogOpen} onClose={() => setFeedbackDialogOpen(false)} />
        </>
    );
}

export default Header;