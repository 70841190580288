import React from 'react';

const Changelog = () => {
  return (
    <div className="container mx-auto p-8">
      <h1 className="text-3xl font-bold mb-8">Changelog</h1>

      <div className="space-y-8">
        {/* Version 1.1 */}
        <div>
          <h2 className="text-2xl font-semibold mb-2">
            Version 1.1 - <span className="text-gray-500">August 27, 2023</span>
          </h2>
          <ul className="list-disc list-inside">
            <li>Added analytics dashboard</li>
            <li>Improved ELO scoring algorithm</li>
          </ul>
        </div>

        {/* Version 1.0 */}
        <div>
          <h2 className="text-2xl font-semibold mb-2">
            Version 1.0 - <span className="text-gray-500">August 1, 2023</span>
          </h2>
          <ul className="list-disc list-inside">
            <li>Initial launch</li>
            <li>Leaderboards for each category of tasks</li>
            <li>User reviews and feedback</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Changelog;